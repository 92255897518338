<template lang="pug">
  AppButton.short(
    :class="{ loading }"
    v-bind="$props"
    v-on="$listeners"
  )
    template(
      v-if="loading"
      v-slot:before
    )
      FaIcon.icon.m-r-10(icon="spinner" spin)
</template>

<script>
  export default {
    props: {
      disabled: Boolean,
      title: {
        type: String,
        required: true
      },
      loading: {
        type: Boolean,
        default: false
      }
    },

    components: {
      AppButton: () => import("@/components/elements/AppButton")
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  $transition-duration: 0.3s

  .app-button
    background: $default-purple
    border: none
    border-radius: 4px
    color: $default-white
    font-size: 0.8rem
    font-weight: 200
    transition: padding $transition-duration ease-in-out

    &.loading
      +gradiented($default-purple-gradient 50% 50%)

      color: $default-white !important
      font-weight: 300 !important
      padding-left: 12px !important
      padding-right: 12px !important

      &:after
        border-radius: 0.25rem
</style>
